<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>
 
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
              <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->
            
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="application/pdf,image/jpeg,image/png"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            /> -->
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>
      
        <DataTable
          :loading="loading"
            ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true" showGridlines
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Network User Master</b> </h5>
              <Button icon="pi pi-refresh" @click="get_list()" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
          </template>

          <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

          <Column header="status" :field="columns[11]">
            <template #body="{ data }">
              <span v-if="data.status=='Active' ||data.status=='active'"><badge :value=data.status severity="success"></badge></span>
            <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge :value=data.status severity="warning"></badge></span>
            <span v-else-if="data.status=='Delete' ||data.status=='Deleted'"><badge :value=data.status severity="danger"></badge></span>
            <span v-else>-</span>
            </template>
          </Column>

           <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>


          <Column header="ID" style="min-width: 3rem" :field="columns[0]">
            <template #body="{ data }">
              {{ data.id }}
            </template>
          </Column>
          <Column header="Name" style="min-width: 5rem" :field="columns[1]">
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </Column>
          <Column header="Address" style="min-width: 5rem" :field="columns[2]">
            <template #body="{ data }">
              {{ data.address }}
            </template>
          </Column>
          <Column header="State" :field="columns[3]">
            <template #body="{ data }">
              {{ data.state}}
            </template>
          </Column>
          <Column header="District" :field="columns[4]">
            <template #body="{ data }">
              {{ data.district }}
            </template>
          </Column>
           <Column header="Taluka" :field="columns[5]">
            <template #body="{ data }">
              {{ data.taluka }}
            </template>
          </Column>
          <Column header="City/Village" :field="columns[6]">
            <template #body="{ data }">
              {{ data.citY_VILLAGE }}
            </template>
          </Column>
           <Column header="Mobile" :field="columns[7]">
            <template #body="{ data }">
              {{ data.mobile }}
            </template>
          </Column>
          <Column header="Email" :field="columns[8]">
            <template #body="{ data }">
              {{ data.email }}
            </template>
          </Column>
          <Column header="Password" :field="columns[9]">
            <template #body="{ data }">
            <Button
                class="p-button-rounded p-button-success p-button-outlined"
                @click="update_pass(data)"
                >Update</Button
              >
                  
            </template>
          </Column>
           <Column header="Pincode" :field="columns[10]">
            <template #body="{ data }">
              {{ data.pincode }}
            </template>
          </Column>
           
           
          <Column header="Photo">
            <template #body="slotProps">
            <span v-if="slotProps.data.profilE_PHOTO==null || slotProps.data.profilE_PHOTO==undefined || slotProps.data.profilE_PHOTO=='' ">-</span>
            <span v-else>  <img
                :src="'https://s3.ap-south-1.amazonaws.com/mahajyoti.docs/helpdesk_master/' +slotProps.data.profilE_PHOTO"
                :alt="'https://s3.ap-south-1.amazonaws.com/mahajyoti.docs/helpdesk_master/' +slotProps.data.profilE_PHOTO"
                class="shadow-2"
                width="50"
             
                
                
              /></span>
            
            </template>
          </Column>
         
          

          
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
               <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              /> 
            </template>
          </Column>
        </DataTable>


        <Dialog
          v-model:visible="update_pass_Dialog"
          :style="{ width: '450px', width: '450px' }"
          header="Update Password"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              
              <div class="field">
                <label for="mobile">Mobile</label>
                <InputText
                  id="mobile"
                  v-model.trim="product.mobile"
                  required="true"
                  autofocus
                  disabled
                  :class="{ 'p-invalid': submitted && !product.mobile }"
                />
              </div>
              <div class="field">
                <label for="mobile">Password</label>
                <InputText
                  id="mobile"
                  v-model.trim="password"
                  required="true"
                  autofocus
                  type="password"
                  :class="{ 'p-invalid': submitted && !password }"
                />
              </div>
              <div class="field">
                <label for="mobile">Password</label>
                <InputText
                  id="mobile"
                  v-model.trim="conf_password"
                  required="true"
                  autofocus
                  type="password"
                  :class="{ 'p-invalid': submitted && !conf_password }"
                />
              </div>
            </div>
            <div class="col-12 md:col-6">
              <Button
                v-if="product.id"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="update_password"
              />
            </div>
            <div class="col-12 md:col-6">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="update_pass_Dialog = false"
              />
            </div>
          </div>
        </Dialog>



        <Sidebar
          v-model:visible="productDialog"
          :baseZIndex="1000"
          position="full"
        >

        <div class="col-12">
            <div class="card">
                <img
                  :src="product.profilE_PHOTO"
                  :alt="product.profilE_PHOTO"
                  class="shadow-2"
                  width="100"
                  v-if="product.profilE_PHOTO"
                />
              <h5>Network User Form</h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-2">
                  <label for="status">Status</label>
                  <Dropdown
                    id="status"
                    v-model="selectstatus"
                    :options="dropdownItems"
                    :value="dropdownItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !selectstatus }"
                    required="true"
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-4">
                  <label for="name">Name</label>
                  <InputText
                    id="name"
                    type="text"
                    v-model.trim="product.name"
                    required="true"
                    autofocus
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="state">State</label>
                  <InputText
                    id="state"
                    type="text"
                    v-model.trim="product.state"
                    required="true"
                    autofocus
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="district">District</label>
                  <InputText
                    id="district"
                    type="text"
                    v-model.trim="product.district"
                    required="true"
                    autofocus
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="taluka">Taluka</label>
                  <InputText
                    id="taluka"
                    type="text"
                    v-model.trim="product.taluka"
                    required="true"
                    autofocus
                  />
                </div>
                 <div class="field col-12 md:col-2">
                  <label for="citY_VILLAGE">City/Village</label>
                  <InputText
                    id="citY_VILLAGE"
                    type="text"
                    v-model.trim="product.citY_VILLAGE"
                    required="true"
                    autofocus
                  />
                </div>
                 <div class="field col-12 md:col-2">
                  <label for="mobile">Mobile</label>
                  <InputText
                    id="mobile"
                    type="text"
                    v-model.trim="product.mobile"
                    required="true"
                    autofocus
                  />
                </div>
                 <div class="field col-12 md:col-2">
                  <label for="email">Email</label>
                  <InputText
                    id="email"
                    type="email"
                    v-model.trim="product.email"
                    required="true"
                    autofocus
                  />
                </div>
                 <div class="field col-12 md:col-2">
                  <label for="pincode">Pincode</label>
                  <InputText
                    id="pincode"
                    type="text"
                    v-model.trim="product.pincode"
                    required="true"
                    autofocus
                  />
                </div>
                <div class="field col-12 md:col-2">
                <label for="file">Photo</label>
                <FileUpload
                  mode="basic"
                  id="file_attachment"
                  ref="file_attachment"
                  @change="handleFileUpload"
                  name="demo[]"
                  accept="application/pdf,image/jpeg,image/png"
                  :maxFileSize="1000000"
                />

                </div><div class="field col-12 md:col-2">
                    
                </div>

                <div class="field col-12 md:col-12">
                <label for="address">Address</label>
                  <Textarea
                    id="address"
                    type="text"
                    v-model.trim="product.address"
                    required="true"
                    autofocus
                    rows="5"
                  />

                </div>
                <div class="col-12 md:col-2">
              <Button
                v-if="product.id"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Adduser"
               
              />
              <Button
                v-else
                label="ADD"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Adduser"
              />

            </div>
            <div class="col-12 md:col-2">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="productDialog = false;"
              />
            </div>
              </div>
            </div>
        </div>
       
              
            
            
         
        </Sidebar>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteuser"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      page_no:1,
      update_pass_Dialog:false,
      password: "",
      conf_password: "",
      isLoading:false,
			isLoadingModel:false,
			loading: false,
			fullPage:true,
      file_attachment: "",
      
      dropdownItems: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
		{ name: "Deleted", value: "Deleted" }
      ],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course"
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    update_password() {
      if (!this.product.mobile) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Update Mobile NO",
          life: 3000,
        });
        return false;
      }
      if (!this.password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter password",
          life: 3000,
        });
        return false;
      }
      if (!this.conf_password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Confirm Password",
          life: 3000,
        });
        return false;
      }
      if (this.conf_password != this.password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Confirm Password Not Match With Password",
          life: 3000,
        });
        return false;
      }

      //api
      var data = {
        username: this.product.email,
        password: this.password,
        type: "network",
      };
      this.isLoadingModel = true;
      var promise = apis.passwordreset(data, this.id);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.$swal(response.data.message);
        this.update_pass_Dialog = false;
        this.get_list();
      });
    },

     update_pass(product) {
    
      this.password = "";
      this.conf_password = "";
      this.product = { ...product };
      this.update_pass_Dialog = true;
    },
    //a simple date formatting function
 dateFormat(inputDate,info) {},
    handleFileUpload() {
      this.file_attachment = this.$refs.file_attachment.files[0];

      //alert(this.$refs.file_attachment.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var data = {
        count: true,
      };
      this.loading = true;
      var promise = apis.helpdesknetworkusersmaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      var data = {
        Limit: this.limit,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.helpdesknetworkusersmaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
  async Adduser() {
this.submitted = true;
    
    
      
       //edit
      if (this.product.id) {
        
        var d = new Date();
        var file_name3="";
        if(this.file_attachment.type == undefined) 
        {
          file_name3 = this.product.profilE_PHOTO;
        } 
        else 
        {
          file_name3 =  d.getTime() + "_" + d.getMilliseconds() + "_" + this.file_attachment.name;
        }

        var data = {
            "id":this.product.id,
    "Name": this.product.name,
    "address": this.product.address,
    "state": this.product.state,
    "disrtict": this.product.district,
    "taluka": this.product.taluka,
    "citY_VILLAGE": this.product.citY_VILLAGE,
    "mobile": this.product.mobile,
    "email": this.product.email,
    "pincode": this.product.pincode,
    "status": this.selectstatus.value,
    "createD_BY": localStorage.getItem("full_name"),
    "profilE_PHOTO": file_name3
        };
        this.isLoadingModel = true;
        var promise = apis.helpdesknetworkuseredit(data);
        promise
          .then((responseapi) => {
            if (responseapi.data.status == true) {
              if (this.file_attachment.name) {
                var file_data={
                  filePath:"helpdesk_master/" + file_name3,
                  contentType: this.file_attachment.type

                }
                var promise5 = apis.uploadFile(file_data);
                promise5
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachment, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachment.type,
                        },
                      })
                      .then((response) => {
                        this.$swal(responseapi.data.message);
                        this.get_list();
                        this.productDialog = false;

                        this.isLoadingModel = false;
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                      });
              } else {
                this.$swal(responseapi.data.message);
                // this.hideDialog1();
                this.get_list();
                this.productDialog = false;

                this.isLoadingModel = false;
              }
            } else {
              this.$swal(responseapi.data);
              // this.hideDialog1();
              this.productDialog = false;

              this.isLoadingModel = false;
            }
            //alert(this.totalRecords);










          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
             this.get_list();
             this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        var file_name2 = "";
        var d2 = new Date();
        if (this.file_attachment.type)
        {
            file_name2 =
                  d2.getTime() +
                  "_" +
                  d2.getMilliseconds() +
                  "_" +
                  this.file_attachment.name;
        }
        else
        {
            file_name2 = "";
        }
        var data1 = 
        {
        "Name":  this.product.name,
    "address":  this.product.address,
    "state": this.product.state,
    "disrtict": this.product.district,
    "taluka": this.product.taluka,
    "citY_VILLAGE": this.product.citY_VILLAGE,
    "mobile": this.product.mobile,
    "email": this.product.email,
    "pincode": this.product.pincode,
    "status": this.selectstatus.value,
    "createD_BY":  localStorage.getItem("full_name"),
    "profilE_PHOTO": file_name2 
        };
        this.isLoadingModel = true;
        var promises = apis.helpdesknetworkuseradd(data1);
        promises
          .then((responseapi) => {
            if (responseapi.data.status == true) {
              if (this.file_attachment.name) {
                var file_data={
                  filePath:"helpdesk_master/" + file_name2,
                  contentType: this.file_attachment.type

                }
                var promise5 = apis.uploadFile(file_data);
                promise5
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachment, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachment.type,
                        },
                      })
                      .then((response) => {
                        this.$swal(responseapi.data.message);
                        this.get_list();
                        this.productDialog = false;

                        this.isLoadingModel = false;
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                      });
              } else {
                this.$swal(responseapi.data.message);
                // this.hideDialog1();
                this.get_list();
                this.productDialog = false;

                this.isLoadingModel = false;
              }
            } else {
              this.$swal(responseapi.data);
              // this.hideDialog1();
              this.productDialog = false;

              this.isLoadingModel = false;
            }
            
            











          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

       this.selectstatus={name:"",value:""}
       this.selectstatus2={name:"",value:""}
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
      this.productDialog = true;
       this.selectstatus.value = this.product.status;
       this.selectstatus.name = this.product.status;

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;

      // this.file_attachment = this.product.image;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteuser() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.helpdesknetworkuserdelete(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
